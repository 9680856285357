@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sr-btn-primary {
    @apply w-full justify-center rounded-md border border-transparent bg-gray-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-600 sm:col-start-2 sm:text-sm;
  }
  .sr-btn-destructive {
    @apply py-2 px-4 bg-srPrimary text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:ring-opacity-75;
  }

  .hover-underline-animation {
    display: inline-block;
    position: relative;
  }
  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #006AF5;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  article article {
    @apply py-16 px-4 md:px-16 lg:px-32 text-gray-600;
  }
  article article h3 {
    @apply font-bold text-lg text-gray-800;
    @apply pt-4 pb-2;
  }
  article article div {
    @apply py-2;
  }
  .form-control {
    @apply flex flex-col;
  }
  .label {
    @apply flex select-none items-center justify-between px-1 py-2;
  }
  .label-text {
    @apply text-sm;
  }
  .label-text-alt {
    @apply text-xs;
  }
  .btn {
    @apply flex flex-row items-center justify-center mx-1 px-4 py-2 rounded-full text-srPrimaryText bg-gray-50 hover:bg-gray-100;
  }
}

#progress-bar {
  --scrollAmount: 0%;
  width: var(--scrollAmount);
  position: fixed;
  top: 88px;
}

.gradient-bg {
  background-image: linear-gradient(160deg, rgba(79, 208, 255, 1) 0%, rgba(20, 98, 255, 1) 100%);
}

/* DO NOT REMOVE */
.control-dots {
  z-index: -1 !important;
}
.carousel.carousel-slider {
  overflow: visible !important;
}
.carousel .slider-wrapper {
  overflow: visible !important;
}